<style scoped>
.img_list {
  display: flex;
  height: 150px;
  line-height: 150px;
  margin-bottom: 20px;
}

.img_title {
  color: #606266;
  font-weight: 700;
  width: 80px;
  line-height: 150px;
  text-align: right;
  padding-right: 15px;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar {
  width: 150px;
  height: 150px;
  /*border-radius: 50%;*/
  display: block;
}

.top >>> .el-upload--text {
  height: 150px;
  width: 150px;
}
</style>
<template>
  <div class="container">
    <el-tabs v-model="activeName" :before-leave="changetabs" class="my_tab">
      <el-tab-pane label="基本信息" name="0">
        <el-form
          ref="ruleForm"
          :model="userinfo"
          :rules="rules"
          label-width="100px"
          class="demo-ruleForm"
          size="medium "
        >
          <el-form-item label="账号" prop="adminname">
            <el-input v-model="userinfo.adminname" disabled></el-input>
          </el-form-item>
          <el-form-item label="姓名" prop="name">
            <el-input v-model="userinfo.name"></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="mobile">
            <el-input v-model.number="userinfo.mobile"></el-input>
          </el-form-item>
          <el-form-item label="邮箱" prop="email">
            <el-input v-model="userinfo.email"></el-input>
          </el-form-item>
          <el-form-item style="text-align: center">
            <el-button type="primary" @click="submitForm('ruleForm')"
              >确认</el-button
            >
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="个人设置" name="1">
        <el-form
          :model="userinfo"
          label-width="100px"
          class="demo-ruleForm"
          size="medium "
        >
          <el-form-item label="密    码" prop="password">
            <el-input
              v-model="userinfo.password"
              style="width: 200px"
              disabled
            ></el-input>
            <el-button type="primary" @click="showRsetPwddialog"
              >修改密码
            </el-button>
          </el-form-item>
        </el-form>
      </el-tab-pane>
    </el-tabs>

    <!-- 用户对话框-->
    <el-dialog
      title="设置密码"
      width="600px"
      v-model="respwddigShow"
      :close-on-click-modal="false"
    >
      <el-form :model="formuserinfo" :rules="rulesFrom" ref="rulesFrom">
        <el-form-item label="手机号码" label-width="100px" prop="mobile">
          <el-input
            v-model="formuserinfo.mobile"
            style="width: 350px"
            disabled
          ></el-input>
        </el-form-item>

        <el-form-item label="密 码" label-width="100px" prop="password">
          <el-input
            type="password"
            v-model="formuserinfo.password"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="确认密码"
          label-width="100px"
          prop="verifyPassword"
        >
          <el-input
            type="password"
            v-model="formuserinfo.verifyPassword"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="respwddigShow = false">取 消</el-button>
        <el-button type="primary" @click="resetPassword('rulesFrom')"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import SYSADMIN from "../../api/sys/api_admin.js";

export default {
  data() {
    let validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.formuserinfo.checkpassword !== "") {
          this.$refs.rulesFrom.validateField("checkpassword");
        }
        callback();
      }
    };
    let validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.formuserinfo.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      // imageUrl: '',
      activeName: "0",
      timer: undefined,
      showcodedialog: false,

      active: 0,
      changeMobileActive: 0,
      defaultProps: { children: "subs", label: "title" },
      // userinfo: this.$store.state.cuttuser,
      shieldingAlarm: window.localStorage.shielding_Alarm
        ? localStorage.shielding_Alarm
        : 1,

      userinfo: {},
      showbingdialog: false,
      showchangemobiledialog: false,
      formuserinfo: { codeText: "获取验证码", time: 0 },
      viewList: [],
      isView: false,
      viewId: 0,
      pwdinfo: { oldpwd: undefined, pwd: undefined, checkpwd: undefined },
      truedigShow: false,

      respwddigShow: false,
      rules: {
        // nickname: [{ required: true, message: "请输入昵称", trigger: "blur" }],
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        mobile: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          {
            validator: function (rule, value, callback) {
              if (/^1[3456789]\d{9}$/.test(value) == false) {
                callback(new Error("手机号格式错误"));
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
        email: [
          { required: true, message: "请输入邮箱地址", trigger: "blur" },
          {
            type: "email",
            message: "请输入正确的邮箱地址",
            trigger: "blur,change",
          },
        ],
      },
      rulesFrom: {
        mobile: [
          {
            required: true,
            pattern: /^1\d{10}$/,
            message: "请输入正确的手机号码！",
            trigger: "blur",
          },
        ],

        password: [
          { required: true, validator: validatePass, trigger: "blur" },
        ],
        verifyPassword: [
          { required: true, validator: validatePass2, trigger: "blur" },
        ],
      },
    };
  },
  watch: {
    "$store.state.cuttuser": function () {
      this.initData();
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.initData();
    },
    initData() {
      SYSADMIN.currentUser().then((res) => {
        console.log("currentUser " + JSON.stringify(res));
        if (res.status == 1) {
          this.userinfo = res.data;
        }
      });
      // this.userinfo=userinfo;
    },

    // ===================================================绑定手机号码==============================================================
    // verifyTelephone(val) {
    //   this.formuserinfo.time = /^1[3456789]\d{9}$/.test(val) ? 0 : 60;
    // },

    changetabs(activeName, oldActiveName) {},
    //update tab1
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          SYSADMIN.updateUser({
            username: this.userinfo.username,
            name: this.userinfo.name,
            email: this.userinfo.email,
            mobile: this.userinfo.mobile,
            id: this.userinfo.id,
          }).then((res) => {
            if (res.status == 1) {
              this.$message.success("修改成功！");
            } else {
              this.$message.error(res.message);
            }
          });
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },

    // ===================================================修改密码==============================================================
    showRsetPwddialog() {
      this.formuserinfo = {
        mobile: this.userinfo.mobile,
        codeText: "获取验证码",
        time: 0,
      };
      this.respwddigShow = true;
    },

    resetPassword(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          SYSADMIN.resetpassword({
            id: this.userinfo.id,
            verifyPassword: this.formuserinfo.verifyPassword,
            password: this.formuserinfo.password,
          }).then((res) => {
            if (res.status == 1) {
              this.respwddigShow = false;
              this.$message.info("修改成功！");
            } else {
              this.$message.error(res.message);
            }
          });
        } else {
          return false;
        }
      });
    },
  },
};
</script>
